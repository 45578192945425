

























































































import { Vue, Component } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiShareInfo, apiShareEdit } from '@/api/setting/shop'

@Component({
    components: {
        MaterialSelect
    }
})
export default class SettingShare extends Vue {
    /** S Data **/
    // 表单数据
    form: any = {
        share_page: 0, // 分享页面
        share_title: '', // 分享标题
        share_intro: '', // 分享简介
        share_image: '' // 分享图片
    }

    // 表单验证
    rules: object = {
        share_image: [
            {
                required: true,
                message: '请上传分享图片',
                trigger: 'blur'
            }
        ],
        share_page: [
            {
                required: true,
                message: '请选择分享页面',
                trigger: 'blur'
            }
        ]
    }

    shareImageExample = require('@/assets/images/setting/img_shili_share_example.png')
    /** E Data **/

    /** S Methods **/
    // 初始化表单数据
    initFormData() {
        apiShareInfo()
            .then(res => {
                // 表单同步于接口响应数据
                for (const key in res) {
                    if (!this.form.hasOwnProperty(key)) {
                        continue
                    }
                    this.form[key] = res[key]
                }
            })
            .catch(() => {
                this.$message.error('数据加载失败，请刷新重载')
            })
    }

    // 重置表单数据
    onResetFrom() {
        this.initFormData()
        this.$message.info('已重置数据')
    }

    // 提交表单
    onSubmitFrom(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement

        refs.validate((valid: boolean) => {
            if (!valid) {
                return
            }
            const loading = this.$loading({ text: '保存中' })

            apiShareEdit({
                ...this.form
            })
                .then(() => {
                    this.$message.success('保存成功')
                })
                .catch(() => {
                    this.$message.error('保存失败')
                })
                .finally(() => {
                    loading.close()
                })
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.initFormData()
    }
    /** E Life Cycle **/
}
